<template>
  <v-card>
    <v-card-title class="header">{{title}}</v-card-title>
    <v-card-text>
      <v-col>
        <v-row>
          <v-col>
            <v-menu
              ref="startRef"
              v-model="startMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field :color="color" v-model="startDate" :label="$t('start')" v-on="on"></v-text-field>
              </template>
              <v-date-picker v-model="startDate" @input="startMenu = false"></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-menu
              ref="endRef"
              v-model="endMenu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field :color="color" v-model="endDate" :label="$t('end')" v-on="on"></v-text-field>
              </template>
              <v-date-picker v-model="endDate" :min="minEndDate" @input="endMenu = false"></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </v-col>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn :color="color" @click.prevent="download" :disabled="!valid()" :loading="loader">{{$t('download')}}</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import { DateTimeHelper } from '@appsocially/timepassport'

export default {
  props: {
    color: { default: () => { return 'amber lighten-2' } },
    title: { default: () => { return '' } }
  },
  data () {
    return {
      startMenu: false,
      endMenu: false,
      startDate: '',
      endDate: '',
      loader: false
    }
  },
  methods: {
    valid () {
      return this.startDate !== '' && this.endDate !== ''
    },
    download () {
      this.loader = true
      const { start, end } = this
      this.$emit('download', { start, end })
      this.startDate = this.endDate = ''
      this.loader = false
    }
  },
  computed: {
    minEndDate () {
      return this.startDate
    },
    start () {
      if (this.startDate === '') {
        return null
      }
      return DateTimeHelper.parse(`${this.startDate} 00:00`)
    },
    end () {
      if (this.endDate === '') {
        return null
      }
      return DateTimeHelper.parse(`${this.endDate} 23:59`)
    }
  },
  i18n: {
    messages: {
      en: {
        start: 'Start',
        end: 'End',
        download: 'Download'
      },
      ja: {
        start: '開始日時',
        end: '終了日時',
        download: 'ダウンロード'
      }
    }
  }
}
</script>
