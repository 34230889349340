
/**
 * To add a new part of script:
 * 1. Add a new .upil script file in this directory
 * 2. Add a new import below
 * 3. Add the import name to the scripts[] array
 */

import schedulingJa from '!raw-loader!./schedulingJa.upil' // eslint-disable-line import/no-webpack-loader-syntax
import schedulingEn from '!raw-loader!./schedulingEn.upil' // eslint-disable-line import/no-webpack-loader-syntax
import applicationJp from '!raw-loader!./applicationJp.upil' // eslint-disable-line import/no-webpack-loader-syntax
import applicationEn from '!raw-loader!./applicationEn.upil' // eslint-disable-line import/no-webpack-loader-syntax
import chooseTaskJp from '!raw-loader!./chooseTaskJp.upil' // eslint-disable-line import/no-webpack-loader-syntax
import chooseTaskEn from '!raw-loader!./chooseTaskEn.upil' // eslint-disable-line import/no-webpack-loader-syntax
import japanese from '!raw-loader!./japanese.upil' // eslint-disable-line import/no-webpack-loader-syntax
import english from '!raw-loader!./english.upil' // eslint-disable-line import/no-webpack-loader-syntax
import multiLanguage from '!raw-loader!./multiLanguage.upil' // eslint-disable-line import/no-webpack-loader-syntax
import learnMoreEn from '!raw-loader!./learnMoreEn.upil' // eslint-disable-line import/no-webpack-loader-syntax
import learnMoreJp from '!raw-loader!./learnMoreJp.upil' // eslint-disable-line import/no-webpack-loader-syntax
import mainDialog from '!raw-loader!./mainDialog.upil' // eslint-disable-line import/no-webpack-loader-syntax

// Saiyodooooon
import saiyodooooonApplicationJp from '!raw-loader!./saiyodooooon/applicationJp.upil' // eslint-disable-line import/no-webpack-loader-syntax
import saiyodooooonApplicationEn from '!raw-loader!./saiyodooooon/applicationEn.upil' // eslint-disable-line import/no-webpack-loader-syntax

const scripts = [
  schedulingJa,
  schedulingEn,
  chooseTaskJp,
  chooseTaskEn,
  japanese,
  english,
  multiLanguage,
  learnMoreEn,
  learnMoreJp,
  mainDialog
]

switch (process.env.VUE_APP_SERVICE) {
  case 'saiyodooooon':
    scripts.push(saiyodooooonApplicationJp)
    scripts.push(saiyodooooonApplicationEn)
    break
  default:
    scripts.push(applicationJp)
    scripts.push(applicationEn)
}

const combinedScripts = scripts.join('\n')

const finalScript = `
EXTERNAL usesOldJobOpeningType

${combinedScripts}

RUN mainDialog
`

export default finalScript
