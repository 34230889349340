<template>
  <div v-if="show">
    <Popup
      v-bind="popupProp"
      @confirm="popupConfirm"
      @cancel="popupCancel"
      @update:showDialog="showDeletePopup = false"
    />
  </div>
</template>

<script>
import * as Sentry from '@sentry/browser'
import { serviceName } from '@/truffle.config'
import Popup from '@/components/calendar/Popup'
import { cancelInterview } from '@/helpers/firebase-functions'
const ownerScenarioId = process.env.VUE_APP_RECRUIT_SCENARIO_ID

const state = {
  confirm: 'confirm',
  notifyApplicant: 'notify',
  complete: 'complete'
}

export default {
  components: {
    Popup
  },
  props: {
    show: {
      type: Boolean,
      required: true
    },
    attendee: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      popupConfirm: () => {},
      popupCancel: () => {},
      state: state.confirm,
      notifyApplicant: false,
      loading: false
    }
  },
  computed: {
    popupProp () {
      const baseProp = {
        showDialog: this.showDeletePopup,
        confirmLoading: this.loading
      }
      switch (this.state) {
        case state.confirm:
          return {
            ...baseProp,
            title: this.$t('m.calendar.title.deleteAttendee'),
            confirm: this.$t('m.yes'),
            cancel: this.$t('m.no')
          }
        case state.notifyApplicant:
          return {
            ...baseProp,
            title: this.$t('m.cancelInterview.informApplicantTitle'),
            subtitle: this.$t('m.cancelInterview.informApplicantSubtitle', { serviceName }),
            confirm: this.$t('m.yes'),
            cancel: this.$t('m.no')
          }
        case state.complete:
          return {
            ...baseProp,
            title: this.notifyApplicant ? this.$t('m.cancelInterview.ownerCanceledApplicantNotifiedTitle') : this.$t('m.cancelInterview.informApplicantToCancelInterview'),
            subtitle: this.$t('m.cancelInterview.ownerCanceledApplicantNotifiedSubtitle'),
            confirm: this.$t('m.yes'),
            cancel: ''
          }
      }
      return baseProp
    },
    showDeletePopup: {
      get () {
        return this.show
      },
      set (val) {
        this.$emit('update:show', val)
      }
    }
  },
  methods: {
    async cancelScheduledSlot (notifyApplicant = true) {
      try {
        this.notifyApplicant = notifyApplicant
        this.loading = true
        await cancelInterview({
          ownerScenarioId,
          ...this.attendee,
          notifyApplicant
        })

        this.popupConfirm = () => {
          this.showDeletePopup = false
        }
        this.state = state.complete
      } catch (err) {
        console.error('Problem cancelling interview', err) // eslint-disable-line no-console
        Sentry.captureException(err)
      } finally {
        this.loading = false
        this.$emit('confirm')
      }
    },
    async attendeeDeleteConfirmCallback () {
      this.pendingAttendeeDelete = true
      await cancelInterview({
        applicantId: 'refId',
        ownerScenarioId: '',
        attendeeId: 'id',
        schedulerlySlotId: ''
      })
    }
  },
  watch: {
    show (val) {
      if (val) {
        this.state = state.confirm
        this.popupConfirm = () => {
          this.state = state.notifyApplicant
          this.popupConfirm = () => this.cancelScheduledSlot(true)
          this.popupCancel = () => this.cancelScheduledSlot(false)
        }
        this.popupCancel = () => {
          this.showDeletePopup = false
        }
      }
    }
  }
}
</script>

<style>
</style>
