import { addMinutes, isBefore, roundToNearestMinutes } from 'date-fns'

export async function delay (time) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve()
    }, time)
  })
}

export function generateStartEndTime (duration) {
  const now = new Date()
  let nearestTo = duration % 30
  if (nearestTo === 0) {
    nearestTo = 30
  }
  let start = roundToNearestMinutes(now, { nearestTo })
  if (isBefore(start, now)) {
    // Always round up
    start = addMinutes(start, duration)
  }
  const end = addMinutes(start, duration)

  return { start, end }
}

export function isValidDate (d) {
  return d instanceof Date && !isNaN(d)
}
