<template>
  <div>
    <v-card>
      <v-card-text>
        <ApplicantSettings v-bind="propData" />
      </v-card-text>
      <v-card-actions>
        <div class="flex-grow-1"></div>
        <v-btn text :disabled="!savedInterviewId && !id" @click="confirm">OK</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
import ApplicantSettings from '@/components/botSettings/applicantSettings'
import { generateOwnerVarsRef } from '@/listeners/apply'
import { mapState, createNamespacedHelpers } from 'vuex'
import { firestore } from '@/helpers/firestore'
import { COLLECTIONS_ENUM } from '@/enums'
// import { DateTimeHelper } from '@appsocially/timepassport'
// import { addMinutes } from 'date-fns'
import { schedulerlyClient } from '@/helpers/schedulerly'

const { mapState: mapStateCalendar, mapActions: mapActionsCalendar } = createNamespacedHelpers('calendar')

export default {
  components: {
    ApplicantSettings
  },
  props: {
    applicantId: { default: () => { return '' } },
    attendeeId: { default: () => { return '' } },
    addData: { default: () => { return {} } },
    calendarId: { default: () => { return '' } },
    slotId: { default: () => { return '' } }
  },
  data () {
    return {
      ownerData: {},
      applicantData: {},
      loading: true,
      listeners: {
        'input-update': async (payload) => {
          if (payload.event && payload.event.node.input) {
            const { event: { node: { input: { name } } } } = payload
            if (name === 'isoDate') {
              const { isRescheduledInterview, schedulerlySlotId } = await this.getInterviewData()
              this.schedulerlySlotId = schedulerlySlotId
              this.showDeletePreviousSlot = isRescheduledInterview
            }
          }
        }
      },
      newAttendee: false,
      slotChange: false,
      id: '',
      showDeletePreviousSlot: false,
      schedulerlySlotId: '',
      canSave: false
    }
  },
  computed: {
    ...mapState('auth', ['uid']),
    ...mapStateCalendar(['savedInterviewId']),
    propData () {
      return {
        applicantId: this.id,
        applicantData: this.applicantData,
        ownerData: this.ownerData,
        loading: this.loading,
        listeners: this.listeners
      }
    },
    recruitScenarioId () {
      return process.env.VUE_APP_RECRUIT_SCENARIO_ID
    },
    showDeletePreviousSlotPropData () {
      return {
        showDialog: this.showDeletePreviousSlot,
        title: this.$t('m.calendar.title.showDeletePrev'),
        confirm: this.$t('m.yes'),
        cancel: this.$t('m.no')
      }
    }
  },
  methods: {
    ...mapActionsCalendar(['setCalendarSubscriptionInfo', 'setupdateRescheduleConfirmation', 'createInterview', 'resetSavedInterview']),
    async loadOwnerData () {
      this.ownerData = await generateOwnerVarsRef(this.uid).get().then(doc => doc.data())
    },
    async loadApplicantData () {
      this.applicantData = await firestore
        .collection(COLLECTIONS_ENUM.OWNERS)
        .doc(this.uid)
        .collection(COLLECTIONS_ENUM.SCENARIO_PUBLIC)
        .doc(this.recruitScenarioId)
        .collection(COLLECTIONS_ENUM.RESPONSES)
        .doc(this.id)
        .get()
        .then(doc => doc.data())
        .then(({ createdAt, ...data }) => data)
    },
    async getInterviewData () {
      if (this.savedInterviewId) {
        return firestore
          .collection(COLLECTIONS_ENUM.OWNERS)
          .doc(this.uid)
          .collection(COLLECTIONS_ENUM.SCENARIO_PUBLIC)
          .doc(this.recruitScenarioId)
          .collection(COLLECTIONS_ENUM.INTERVIEWS)
          .doc(this.savedInterviewId || this.applicantId)
          .get()
          .then(doc => doc.data())
      } else if (this.applicantId) {
        return firestore
          .collection(COLLECTIONS_ENUM.OWNERS)
          .doc(this.uid)
          .collection(COLLECTIONS_ENUM.SCENARIO_PUBLIC)
          .doc(this.recruitScenarioId)
          .collection(COLLECTIONS_ENUM.INTERVIEWS)
          .where('applicantId', '==', this.applicantId)
          .limit(1)
          .get()
          .then(snap => {
            if (snap.empty) {
              return {}
            }
            return snap.docs[0].data()
          })
      } else {
        return {}
      }
    },

    confirm () {
      Promise.all([
        this.loadApplicantData(),
        this.getInterviewData()
      ]).then(async results => {
        const { applicantName, applicantEmail, applicantPhone } = this.applicantData
        let { schedulerlySlotId, schedulerlyAttendeeId } = results[1]
        if (schedulerlySlotId) {
          this.schedulerlySlotId = schedulerlySlotId
        }

        const attendee = {
          name: applicantName,
          contact: applicantEmail || applicantPhone,
          refId: this.id,
          email: applicantEmail || '',
          phoneNumber: applicantPhone || ''
        }

        schedulerlyClient.updateAttendee({
          slotId: this.schedulerlySlotId,
          attendeeId: schedulerlyAttendeeId,
          attendee
        })
        const oldSlot = {
          id: this.slotId,
          ...this.createdSlot
        }
        this.editedSlot = {
          ...this.editedSlot,
          isoDate: this.addData.isoDate,
          id: schedulerlySlotId,
          attendees: [
            ...((this.editedSlot || {}).attendees || []).filter(({ refId }) => (refId !== attendee.refId)),
            attendee
          ]
        }
        // We don't have to wait for schedulerlyClient.updateAttendee to finish
        this.$emit('confirm', {
          reload: false,
          slotId: this.schedulerlySlotId,
          slot: this.editedSlot,
          oldSlot,
          attendee
        })

        this.resetSavedInterview()
      })
    }
  },
  async mounted () {
    this.schedulerlySlotId = this.slotId
    this.setCalendarSubscriptionInfo({ ownerId: this.uid, scenarioId: this.recruitScenarioId })

    if (this.applicantId === '') {
      const { id } = await firestore
        .collection(COLLECTIONS_ENUM.OWNERS)
        .doc(this.uid)
        .collection(COLLECTIONS_ENUM.SCENARIO_PUBLIC)
        .doc(this.recruitScenarioId)
        .collection(COLLECTIONS_ENUM.RESPONSES)
        .add({ createdAt: new Date(), ...this.addData })
      this.newAttendee = true
      this.id = id

      const { isoDate: { isoDate, startTime, duration } } = this.addData

      this.createInterview({
        slot: {
          duration,
          isoDate,
          startTime,
          timeZone: new Intl.DateTimeFormat().resolvedOptions().timeZone,
          schedulerlySlotId: this.schedulerlySlotId
        },
        applicantId: id,
        applicantProfile: { name: '', contact: '', email: '', phoneNumber: '' },
        isConfirmed: true
      }).then(({ slot: createdSlot }) => {
        // This will delay the Ok button's enable time
        // We need the interview to be created before we can save
        this.canSave = true
        this.createdSlot = createdSlot
      })
    } else {
      this.id = this.applicantId
      this.canSave = true
    }

    this.setupdateRescheduleConfirmation(!this.newAttendee)

    await Promise.all([this.loadOwnerData(), this.loadApplicantData()])

    this.loading = false
  },
  destroyed () {
    this.setupdateRescheduleConfirmation(false)
  }
}
</script>
