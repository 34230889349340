import isMobilePhone from 'validator/lib/isMobilePhone'
import { email } from 'vee-validate/dist/rules'

const baseEmail = (missingMsg, emailWrongMessage) => [
  value => value && value.length > 0 ? true : missingMsg,
  value => email.validate(value) ? true : emailWrongMessage
]

const basePhone = (missingMsg, phoneWrongMessage) => [
  value => value && value.length > 0 ? true : missingMsg,

  // https://github.com/validatorjs/validator.js#strings-only
  value => isMobilePhone(value + '') ? true : phoneWrongMessage
]

const emailType = (locale) => {
  switch (locale) {
    case 'ja':
      return baseEmail('必須項目です', '有効なメールアドレスではありません')
    default:
      return baseEmail('Required', 'Invalid email address')
  }
}

const phoneType = (locale) => {
  switch (locale) {
    case 'ja':
      return basePhone('必須項目です', '有効な電話番号ではありません')
    default:
      return basePhone('Required', 'Invalid phone number')
  }
}

export default {
  email: emailType,
  phone: phoneType
}
