import { analyticsEvent, EVENT_NAMES } from '@/helpers/analytics'
import { symbols } from '@appsocially/userpil-core'
import { firebase } from '@/helpers/firebase'

export function userInputAnalyticsEvent ({ inputValue, text, name }) {
  switch (name) {
    // Contains recursive variable which breaks app if mixpanel tries to serialize so we'll make this exception for now
    case 'isoDate':
      return
    default:
      analyticsEvent(EVENT_NAMES.USER_INPUT_DATA, {
        inputValue,
        textToUser: text,
        inputName: name
      })
  }
}

export function userInputValueCheck (value) {
  switch (value) {
    case symbols.UNRESOLVED:
      return firebase.firestore.FieldValue.delete()
    default:
      return value
  }
}
