<template>
  <div>
    <DateFilter v-bind="filterProp" @download="downloadICS" />
    <a v-show="false" ref="doDownload" :href="icsUrl" download="calendar.ics">click</a>
  </div>
</template>
<script>
// import schedulerlyService from '@/helpers/schedulerly'
import DateFilter from '@/components/shared/startEndDateFilter'
import { getCalendarICS } from '../../helpers/firebase-functions'

export default {
  props: {
    color: { default: () => { return 'amber lighten-2' } },
    calendarId: { default: () => { return null } }
  },
  data: () => ({
    icsUrl: ''
  }),
  components: { DateFilter },
  methods: {
    async downloadICS ({ start, end }) {
      const result = await getCalendarICS({
        calendarId: this.calendarId,
        start: start.toISOString(),
        end: end.toISOString(),
        includeGenerated: false
      }).then(data => data.data)

      this.icsUrl = URL.createObjectURL(result)

      this.$nextTick(() => {
        this.$refs.doDownload.click()
        this.$emit('complete')
      })
    }
  },
  computed: {
    filterProp () {
      return {
        color: this.color,
        title: this.$t('m.calendar.title.export')
      }
    }
  }
}
</script>
