 <template>
  <div id="calendar-component">
    <v-dialog v-model="isBusy" persistent></v-dialog>
    <Calendar
      ref="calendar-component"
      :events="renderedEvents"
      :filterStatus="slotfilter"
      @intervalClick="click"
      @add="addEvent"
      :addOptions="addOptions"
      :customEvents="false"
      :intervalThreshold="30"
      :intervalStart="intervalStart"
      :intervalEnd="intervalEnd"
      :height="600"
      :color="styleColor"
      :isBusy="isBusy"
      :touch="true"
      :locale="$i18n.locale"
      :additionToolbarButtons="additionToolbarButtons"
      defaultType="week"
      v-show="showCalendar"
    ></Calendar>

    <v-dialog width="400" v-model="editMeetingDialog" persistent>
      <EditMeeting
        v-if="editMeetingDialog"
        v-bind="editedSlot"
        :locale="$i18n.locale"
        @delete="onDeleteSlot"
        @save="closeEditDialog"
        @update="onUpdateSlot"
        :allowEdit="true"
        :color="styleColor"
        :showCloseButton="false"
      >
        <template v-slot:attendee-list>
          <v-row dense justify="center">
            <v-col cols="8" align-self="center">{{
              $t("m.calendar.attendee.max", { max: editedSlot.maxAttendees })
            }}</v-col>
            <v-col cols="4" align-self="center">
              <v-btn
                v-if="editedSlot.attendees.length < editedSlot.maxAttendees"
                text
                @click="showEditAttendee()"
              >
                <v-icon small>mdi-plus</v-icon>
                {{ $t("m.calendar.attendee.add") }}
              </v-btn>
            </v-col>
          </v-row>
          <attendee-list
            :attendees="editedSlot.attendees"
            @editClicked="showEditAttendee"
            @deleteClicked="showRemoveAttendee"
          ></attendee-list>
        </template>
      </EditMeeting>
    </v-dialog>
    <v-sheet v-if="editAttendee" :height="600">
      <Attendee
        @confirm="onAttendeeSaved"
        :applicantId="editedAttendee.refId"
        :attendeeId="editedAttendee.id"
        :addData="attendeeAddData"
        :calendarId="currentCalendar.id"
        :slotId="editedSlot.id"
      />
    </v-sheet>

    <v-dialog width="400" v-if="addMeetingDialog" v-model="addMeetingDialog">
      <AddMeeting
        v-bind="addedSlot"
        :color="styleColor"
        @cancel="addMeetingDialog = false"
        @save="onAddSlot"
        :locale="$i18n.locale"
      ></AddMeeting>
    </v-dialog>
    <v-dialog width="400" v-model="exportCalendarDialog">
      <Export
        v-if="exportCalendarDialog"
        :calendarId="currentCalendar.id"
        @complete="exportCalendarDialog = false"
      ></Export>
    </v-dialog>
    <Popup
      v-bind="addSlotWarningPopupProp"
      @cancel="showAddWarning = false"
      @confirm="confirmAddSlot()"
      @update:showDialog="showAddWarning = false"
    />
    <Popup
      v-bind="maxAttendeeExceedWarningPopupProp"
      @confirm="showMaxAttendeeWarning = false"
      @update:showDialog="showMaxAttendeeWarning = false"
    />
    <Popup
      v-bind="startTimeChangeWarningPopupProp"
      @confirm="showStartTimeChangeEditWarning = false"
      @update:showDialog="showStartTimeChangeEditWarning = false"
    />
    <Popup
      v-bind="showRescheduleInstructionProp"
      @confirm="showRescheduleInstruction = false"
      @update:showDialog="showRescheduleInstruction = false"
    />
    <CancelInterview
      :show.sync="showAttendeeDeleteWarning"
      :attendee="deleteAttendee"
      @confirm="removeAttendee"
    />
  </div>
</template>

<script>

import { Calendar, EditMeeting, AddMeeting, DateTimeHelper } from '@appsocially/timepassport'
import Attendee from '@/components/calendar/Attendee'
import AttendeeList from '@/components/calendar/AttendeeList'
import { mapState, createNamespacedHelpers } from 'vuex'
import { isAfter, isWithinInterval, getHours, startOfTomorrow, addMinutes } from 'date-fns'
import '@appsocially/timepassport/dist/timepassport.css'
import Popup from '@/components/calendar/Popup'
import Export from '@/components/calendar/Export'
import CancelInterview from '@/components/calendar/CancelInterview'
import 'vuetify/lib/directives/touch'
import { EVENT_NAMES, analyticsEvent } from '@/helpers/analytics'
import { getInterviews, getApplicantData, calculateStartInterval, calculateEndInterval, checkOverlap, updateResponse } from '@/helpers/calendar'
import { withSentry } from '@/helpers/sentry'
import { formatDateTime, parseDateTimeString } from '@appsocially/schedulerly-client/src/helpers/time'
import { generateRuleSlots } from '@appsocially/schedulerly-client/src/helpers/rule'
import { generateStartEndTime, isValidDate } from '@/helpers/time'
const { mapState: mapStateCalendar, mapActions: mapActionsCalendar } = createNamespacedHelpers('calendar')

export default {
  components: {
    Calendar,
    EditMeeting,
    AddMeeting,
    Popup,
    Attendee,
    AttendeeList,
    Export,
    CancelInterview
  },
  data () {
    return {
      calendars: [],
      currentCalendar: {},
      editMeetingDialog: false,
      editedSlot: {},
      addMeetingDialog: false,
      defaultDuration: 30,
      addedSlot: {},
      editedAttendee: {},
      actionQueue: [],
      showAddWarning: false,
      addConfirmCallback: () => { },
      showMaxAttendeeWarning: false,
      editAttendee: false,
      attendeeAddData: {},
      showAttendeeDeleteWarning: false,
      showStartTimeChangeEditWarning: false,
      additionToolbarButtons: [{
        show: false,
        icon: 'mdi-download',
        listener: () => {
          this.exportCalendarDialog = true
        }
      }],
      exportCalendarDialog: false,
      interviews: [],
      deleteAttendee: {},
      showRescheduleInstruction: false
    }
  },
  computed: {
    ...mapState('auth', ['uid']),
    ...mapStateCalendar(['loadedRulesAndSlots', 'scheduledSlots', 'scheduledRules']),
    slots () {
      const slots = this.scheduledSlots.map(slot => {
        return {
          attendees: [],
          maxAttendees: 1,
          status: 'open',
          ...slot
        }
      })

      // XXX: This method should be moved to timepassport
      // Duplicated data???????????????
      // const interviewSlots = (this.interviews || [])
      //   .filter(({ schedulerlySlotId }) => schedulerlySlotId)
      //   .map(({ schedulerlySlotId, isoDate, startTime, duration, schedulerlyAttendeeId, applicantId }) => {
      //     const from = DateTimeHelper.parse(`${isoDate} ${startTime}`)
      //     const to = DateTimeHelper.addMinutes(from, duration)
      //     return {
      //       from: from.toISOString(),
      //       to: to.toISOString(),
      //       maxAttendees: 1,
      //       attendees: [{ id: schedulerlyAttendeeId, refId: applicantId }],
      //       status: 'open',
      //       id: schedulerlySlotId
      //     }
      //   })
      // interviewSlots.forEach((slot) => {
      //   const index = slots.findIndex(({ id }) => id === slot.id)
      //   if (slots.some(({ id }) => id === slot.id)) {
      //     slots[index].attendees = slots[index].attendees.concat(slot.attendees)
      //   } else {
      //     slots.push(slot)
      //   }
      // })

      return slots
    },
    addOptions () {
      return [
        { name: this.$t('m.calendar.createMultipleSlot'), value: 'multiple' },
        { name: this.$t('m.calendar.createSingleSlot'), value: 'single' }
      ]
    },
    slotfilter () {
      return [
        { name: this.$t('m.calendar.all'),
          value: [
            'unavailable',
            'pending',
            'open',
            'action'
          ]
        },
        { name: this.$t('m.calendar.scheduled'), value: ['action', 'pending'] },
        { name: this.$t('m.calendar.available'), value: ['open', 'pending'] }
      ]
    },
    generatedSlots () {
      // XXX: This method should be moved to timepassport
      if (!this.loadedRulesAndSlots) {
        return []
      }
      let startDate = startOfTomorrow()
      const calendar = this.$refs['calendar-component']

      if (calendar) {
        const { start: { date: calendarStart } } = calendar
        const calendarStartDate = parseDateTimeString(`${calendarStart} 00:00`)

        if (isAfter(calendarStartDate, startDate)) {
          startDate = calendarStartDate
        }
      }
      return generateRuleSlots({
        startDate,
        rules: this.scheduledRules,
        numberOfDays: 31
      })
        .map(({ duration, startTime }) => {
          return {
            from: startTime.toISOString(),
            to: addMinutes(startTime, duration).toISOString()
          }
        })
    },
    events () {
      // XXX: This method should be moved to timepassport
      return []
        .concat(this.slots)
        .concat(this.generatedSlots.map(slot => {
          return {
            ...slot,
            attendees: [],
            maxAttendees: 1,
            generated: true
          }
        }))
        .map(event => {
          const { id, from, to, attendees, maxAttendees } = event
          return {
            id,
            start: formatDateTime(new Date(from)),
            end: formatDateTime(new Date(to)),
            startISO: from,
            endISO: to,
            startDate: new Date(from),
            endDate: new Date(to),
            attendees,
            maxAttendees,
            status: this.getEventStatus({ attendees, maxAttendees }),
            color: this.updateEventColor({ id, attendees, maxAttendees })
          }
        })
    },

    renderedEvents () {
      // XXX: This method should be moved to timepassport
      const calendar = this.$refs['calendar-component']
      const events = this.events
      if (!calendar) {
        return []
      }

      const { start: { date: calendarStart }, end: { date: calendarEnd } } = calendar
      const start = parseDateTimeString(`${calendarStart} 00:00`)
      const end = parseDateTimeString(`${calendarEnd} 23:59`)
      let filtered = events.filter(({ start: eventStart }) => isWithinInterval(parseDateTimeString(eventStart), { start, end }))
      return filtered
    },
    intervalStart () {
      // XXX: This method should be moved to timepassport
      return calculateStartInterval(this.events)
    },
    intervalEnd () {
      // XXX: This method should be moved to timepassport
      return calculateEndInterval(this.events)
    },
    styleColor () {
      return 'amber lighten-2'
    },
    isBusy () {
      return this.actionQueue.length > 0
    },
    addSlotWarningPopupProp () {
      return {
        showDialog: this.showAddWarning,
        title: this.$t('m.calendar.title.addSlot'),
        confirm: this.$t('m.yes'),
        cancel: this.$t('m.no'),
        color: this.styleColor
      }
    },
    maxAttendeeExceedWarningPopupProp () {
      return {
        showDialog: this.showMaxAttendeeWarning,
        title: this.$t('m.calendar.title.maxAttendee'),
        confirm: this.$t('m.ok'),
        color: this.styleColor
      }
    },
    startTimeChangeWarningPopupProp () {
      return {
        showDialog: this.showStartTimeChangeEditWarning,
        title: this.$t('m.calendar.title.startTime'),
        confirm: this.$t('m.ok'),
        color: this.styleColor
      }
    },
    showRescheduleInstructionProp () {
      return {
        showDialog: this.showRescheduleInstruction,
        title: this.$t('m.calendar.reschedule.title'),
        confirm: this.$t('m.ok'),
        color: this.styleColor
      }
    },
    showCalendar () {
      return !this.editAttendee
    }
  },
  methods: {
    ...mapActionsCalendar(['authenticateSchedulerlyClient', 'getScheduledSlotsAndRules', 'removeAttendeeFromSlot', 'setCalendarSubscriptionInfo', 'addSlots', 'removeSlot', 'getCalendars', 'updateSlot', 'replaceScheduledSlots', 'removeScheduledSlot']),
    async loadUserRules () {
      this.setCalendarSubscriptionInfo({ ownerId: this.uid, scenarioId: process.env.VUE_APP_RECRUIT_SCENARIO_ID })
      await withSentry(this.getScheduledSlotsAndRules({ withAttendeeData: true }))
      this.defaultDuration = this.scheduledRules.duration || 30
    },
    async onUpdateSlot (data) {
      const { maxAttendees, startTime } = data
      if ((this.editedSlot.attendees || []).length > maxAttendees) {
        this.showMaxAttendeeWarning = true
        return
      } else if ((this.editedSlot.attendees || []).length > 0 && this.editedSlot.start !== startTime) {
        this.showStartTimeChangeEditWarning = true
        return
      }

      // TODO: should be wrapped with loader
      this.editedSlot = await this.wrapLoading(
        this.updateSlot({ editedSlot: this.editedSlot, data })
      )
    },
    async click (e) {
      // XXX: This method should be properly named
      // XXX: Actions should also be properly named
      const { event, action } = e
      const { start } = event

      // XXX: start can be 2020-12-13 -1:00 we might need to fix it??
      const startDate = parseDateTimeString(start)
      if (!isValidDate(startDate)) {
        return
      }

      const endDate = addMinutes(startDate, this.defaultDuration)
      const addFn = () => this.wrapLoading(
        withSentry(
          this.addSlots({
            ...event,
            times: [{
              from: startDate,
              to: endDate
            }]
          })
        )
      )
      if (action === 'open') {
        if (checkOverlap({
          start: formatDateTime(startDate),
          end: formatDateTime(endDate),
          events: this.events
        })) {
          this.showAddWarning = true
          this.addConfirmCallback = addFn
        } else {
          await addFn()
        }
      }

      if (action === 'closed') {
        await withSentry(this.onEditSlot(event))
      }
    },
    addEvent ({ type }) {
      // XXX: What the fuck does this do? Probably this should be named onEventAdded?
      const addSingleSlot = type === 'single'
      const { start, end } = generateStartEndTime(this.defaultDuration)

      this.addedSlot = {
        start,
        end,
        defaultDuration: this.defaultDuration,
        addSingleSlot,
        defaultAttendees: 1
      }

      this.addMeetingDialog = true
    },
    async onAddSlot ({ start, end, duration, attendees }) {
      const confirmCallback = async () => {
        const from = parseDateTimeString(start)
        const to = parseDateTimeString(end)
        let nextFrom = from
        let nextTo = addMinutes(from, duration)
        let times = []
        while (!isAfter(nextTo, to)) {
          times = times.concat([{
            from: nextFrom,
            to: nextTo
          }])
          nextFrom = addMinutes(nextFrom, duration)
          nextTo = addMinutes(nextFrom, duration)
        }

        await withSentry(this.addSlots({
          times,
          maxAttendees: attendees
        }))
      }

      this.addMeetingDialog = false

      if (checkOverlap({ start, end, events: this.events })) {
        this.addConfirmCallback = confirmCallback
        this.showAddWarning = true
      } else {
        await withSentry(confirmCallback())
      }
    },
    async onEditSlot (slot) {
      await Promise.all(slot.attendees.map(async attendee => {
        if (!attendee.name) {
          const { applicantName } = await withSentry(getApplicantData(this.uid, attendee.refId))
          attendee.name = applicantName
        }
      }))

      this.editedSlot = slot
      this.editMeetingDialog = true
    },
    async removeAttendee () {
      const { attendeeId } = this.deleteAttendee
      const attendeeIndex = this.editedSlot.attendees.findIndex(({ id }) => id === attendeeId)
      this.editedSlot.attendees.splice(attendeeIndex, 1)
    },
    showRemoveAttendee ({ id, refId }) {
      this.deleteAttendee = {
        applicantId: refId,
        attendeeId: id,
        schedulerlySlotId: this.editedSlot.id
      }
      this.showAttendeeDeleteWarning = true
    },
    async onDeleteSlot () {
      await withSentry(this.removeSlot(this.editedSlot)
        .finally(() => {
          this.closeEditDialog()
        }))
    },
    closeEditDialog () {
      this.editMeetingDialog = false
    },
    addLoadingEvent (data) {
      this.actionQueue.push(data || {})
    },
    completeLoadingEvent () {
      this.actionQueue.pop()
    },
    wrapLoading (promise, data) {
      return Promise.resolve(this.addLoadingEvent(data))
        .then(_ => Promise.resolve(promise))
        .finally(() => this.completeLoadingEvent())
    },
    getEventStatus ({ attendees, maxAttendees }) {
      if (attendees.length === 0) {
        return 'open'
      } else if (attendees.length < maxAttendees) {
        return 'pending'
      } else if (attendees.length >= maxAttendees) {
        return 'action'
      } else {
        return 'unavailable'
      }
    },
    updateEventColor ({ id, attendees, maxAttendees }) {
      if (attendees.length === 0) {
        // if (id) {
        //   return 'blue'
        // }
        return 'green'
      } else if (attendees.length < maxAttendees) {
        return 'orange'
      } else /* if (attendees.length >= maxAttendees) */ {
        return 'pink'
      }
    },
    async addAttendee () {
      if (!this.editedSlot.id) {
        // XXX: This should be moved to server
        withSentry(this.removeSlot(this.editedSlot))
      }

      this.editedAttendee = {
        name: '',
        refId: '',
        contact: ''
      }
    },
    async confirmAddSlot () {
      this.showAddWarning = false
      await withSentry(this.addConfirmCallback())
    },
    async showEditAttendee (attendee) {
      if (attendee) {
        this.editedAttendee = attendee
      } else {
        await withSentry(this.addAttendee())
        const startDateString = this.editedSlot.start.split(' ')
        const isoDate = startDateString[0]
        const startTime = startDateString[1]
        this.attendeeAddData = {
          isoDate: {
            startISO: this.editedSlot.startISO,
            duration: this.editedSlot.duration || this.defaultDuration,
            isoDate,
            // schedulerlySlotId: this.editedSlot.id,
            startTime
          }
        }
      }
      this.editMeetingDialog = false
      this.editAttendee = true
    },
    async onAttendeeSaved ({ slot, attendee }) {
      await updateResponse(this.uid, attendee.refId, { formCompleted: true })

      await this.loadUserRules({ withAttendeeData: true })

      this.attendeeAddData = {}
      this.editAttendee = false
      this.editMeetingDialog = true
      this.replaceScheduledSlots({ slot })

      this.editedSlot = this.events.find(event => event.id === this.editedSlot.id)
    },
    scrollToFirstEvent () {
      const calendarComponent = this.$refs['calendar-component']
      if (calendarComponent) {
        const { calendar } = calendarComponent.$refs
        if (calendar) {
          const { lastStart: { date: startDate }, lastEnd: { date: endDate } } = calendar
          const start = parseDateTimeString(`${startDate} 00:00`)
          const end = parseDateTimeString(`${endDate} 23:59`)
          let min = 24
          this.events.map(({ start }) => parseDateTimeString(start)).filter(startTime => isWithinInterval(startTime, { start, end })).forEach(start => {
            const startHour = getHours(start)
            if (startHour < min) {
              min = startHour
            }
          })
          const offset = 1
          calendar.scrollToTime(`${DateTimeHelper.padZero(min - offset)}:00`)
        }
      }
    }
  },
  async created () {
    await this.wrapLoading(withSentry(
      this.authenticateSchedulerlyClient()
        .then(() => Promise.all([
          this.getCalendars({ uid: this.uid }),
          getInterviews(this.uid)
            .then(interviews => {
              this.interviews = interviews
            }),
          this.loadUserRules()
        ]))
    ))

    const slotQuery = this.$route.query.slot
    if (slotQuery) {
      const slot = this.events.filter(event => event.id === slotQuery)[0]
      await withSentry(this.onEditSlot(slot))
      const attendeeQuery = this.$route.query.attendee
      if (attendeeQuery) {
        const attendee = slot.attendees.filter(attendee => attendee.id === attendeeQuery)[0]
        await withSentry(this.showEditAttendee(attendee))
        this.showRescheduleInstruction = true
      }
    }
  },
  mounted () {
    analyticsEvent(EVENT_NAMES.LOADED_CALENDAR)
  }
}
</script>
