<template>
  <v-data-table
    :headers="headers"
    :items="attendees"
    :mobile-breakpoint="0"
    dense
    hide-default-footer
    v-if="attendees.length > 0"
  >
    <template v-slot:item.action="{ item }">
      <v-icon small class="mr-4" @click="editClicked(item)">mdi-pencil</v-icon>
      <v-icon small @click="deleteClicked(item)">mdi-delete</v-icon>
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: 'attendee-list',
  props: {
    attendees: {
      type: Array,
      required: true
    }
  },
  data: function () {
    return {
      headers: [
        {
          text: this.$t('m.calendar.table.name'),
          align: 'left',
          sortable: true,
          value: 'name'
        },
        {
          text: this.$t('m.calendar.table.action'),
          align: 'right',
          sortable: false,
          value: 'action'
        }
      ]
    }
  },
  methods: {
    editClicked (item) {
      this.$emit('editClicked', item)
    },
    deleteClicked (item) {
      this.$emit('deleteClicked', item)
    }
  }
}
</script>
