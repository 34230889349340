import * as Sentry from '@sentry/browser'
import { decodeCampaignInfo, analyticsEvent, EVENT_NAMES, registerApplicantName, registerApplicantEmail } from '@/helpers/analytics'

import { userInputAnalyticsEvent, userInputValueCheck } from './shared'
import once from 'lodash.once'
import i18n from '@/plugins/i18n'
import { parse } from 'date-fns'
// import { interviewAppointmentCreated } from '@/helpers/firebase-functions'
import { firestore } from '@/helpers/firestore'
import { COLLECTIONS_ENUM } from '@/enums'
// import store from '@/store'
import { firebase } from '@/helpers/firebase'
import { symbols } from '@appsocially/userpil-core'
import { delay } from '@/helpers/time'

// const onceInterviewAppointmentCreated = once(function ({ ownerId, ownerScenarioId, savedInterviewId }) {
//   try {
//     if (savedInterviewId) {
//       interviewAppointmentCreated({ ownerId, ownerScenarioId, savedInterviewId })
//     }
//   } catch (err) {
//     Sentry.captureException(err)
//     // eslint-disable-next-line
//     console.error(`Problem with 'interviewAppointmentCreated': `, err)
//   }
// })

const applyScenarioId = process.env.VUE_APP_APPLY_SCENARIO_ID
const recruitScenarioId = process.env.VUE_APP_RECRUIT_SCENARIO_ID

export const generateOwnerVarsRef = ownerId => firestore
  .collection(COLLECTIONS_ENUM.OWNERS)
  .doc(ownerId)
  .collection(COLLECTIONS_ENUM.SCENARIO_PUBLIC)
  .doc(recruitScenarioId)

const generateSaveRef = (ownerId, uid) => firestore
  .collection(COLLECTIONS_ENUM.OWNERS)
  .doc(ownerId)
  .collection(COLLECTIONS_ENUM.SCENARIO_PUBLIC)
  .doc(recruitScenarioId)
  .collection(COLLECTIONS_ENUM.RESPONSES)
  .doc(uid)

const parseFormat = 'yyyy-MM-dd HH:mm'
function parseIsoDateVariable (inputValue) {
  const { isoDate, startTime } = inputValue // JSON.parse(inputValue)

  if (!isoDate || !startTime) {
    return ''
  }

  const jsDate = parse(`${isoDate} ${startTime}`, parseFormat, new Date())
  return jsDate.toISOString()
}

function userInputAnalytics ({ name, inputValue }) {
  switch (name) {
    case 'applicantName':
      registerApplicantName({ applicantName: inputValue })
      break
    case 'applicantEmail':
      registerApplicantEmail({ address: inputValue })
      break
    case 'isoDate':
      analyticsEvent(EVENT_NAMES.SCHEDULED_INTERVIEW, {
        interviewDateTime: parseIsoDateVariable(inputValue)
      })

      break
  }
}

async function parseUserInput ({ type, label, output, input, uid, ownerId, mode }) {
  const fromType = parseType({ type, output })
  return parseLabel({ type, label, output: fromType, input, uid, ownerId, mode })
}

function parseType ({ type, output }) {
  // const { key, value } = output
  switch (type) {
    default:
      return output
  }
}

async function parseLabel ({ label, output, input, uid, ownerId, mode }) {
  const { value } = output
  switch (label) {
    case 'selectLanguage':
      if (mode !== 'form') {
        i18n.locale = value === 'japanese' ? 'ja' : 'en'
      }
      return output
    default:
      return output
  }
}

function completedScenarioEventAnalytics ({ payload, ownerId }) {
  const { context: { store }, encodedCampaignInfo } = payload
  const { input: {
    applicantEmail,
    applicantName,
    desiredDrink,
    desiredJob,
    restaurantName,
    isoDate
  } } = store.getState()

  const campaignInfo = decodeCampaignInfo(encodedCampaignInfo)
  // window.mixpanel = window.mixpanel ? window.mixpanel : recreateMixpanel()
  // const mixpanel = window.mixpanel
  // mixpanel.register(campaignInfo)
  // mixpanel.people.set(campaignInfo)
  analyticsEvent(EVENT_NAMES.COMPLETED_SCENARIO, {
    ...campaignInfo,
    scenarioId: applyScenarioId,
    ownerId,
    ownerScenarioId: recruitScenarioId,
    applicantEmail,
    applicantName,
    desiredDrink,
    desiredJob,
    restaurantName,
    selectedDate: isoDate ? parseIsoDateVariable(isoDate) : undefined
  })
}

export const listeners = (ownerId, uid, campaignInfo) => {
  const ownerVarsPromise = generateOwnerVarsRef(ownerId).get().then(doc => doc.data())
  return {
    external: async (payload, preventDefault) => {
      const { jobOpeningTypeListJa } = await ownerVarsPromise
      preventDefault()
      const { topic } = payload
      switch (topic) {
        // For backwards compatibility with old string data model
        case 'usesOldJobOpeningType':
          return !jobOpeningTypeListJa
        default:
          return symbols.UNRESOLVED
      }
    },
    'preload-input': async () => {
      const ownerVars = await ownerVarsPromise
      return {
        applicantId: uid,
        ...ownerVars
      }
    },
    'scenario-start': (payload, preventDefault) => {
      return new Promise(async (resolve) => {
        analyticsEvent(EVENT_NAMES.LOADED_SCENARIO, {
          scenarioId: applyScenarioId,
          ownerId,
          ownerScenarioId: recruitScenarioId
        })

        const saveRef = generateSaveRef(ownerId, uid)
        await saveRef.set(
          { createdAt: firebase.firestore.FieldValue.serverTimestamp() },
          {
            merge: true
          }
        )
        resolve()
      })
    },
    'scenario-end': once((payload, preventDefault) => {
      return new Promise(async (resolve) => {
        // const savedInterviewId = store.state.calendar.savedInterviewId
        try {
          delay(1000)
            .then(() => {
              const saveRef = generateSaveRef(ownerId, uid)
              return saveRef.set(
                { 'formCompleted': true },
                {
                  merge: true
                }
              )
            })
          // await onceInterviewAppointmentCreated({ ownerId, ownerScenarioId: recruitScenarioId, savedInterviewId })
        } catch (err) {
          Sentry.captureException(err)
          // eslint-disable-next-line
          console.error(`Problem with 'sendFinalHiringOnboardingInformation': `, err)
        } finally {
          const payload_ = {
            ...payload,
            encodedCampaignInfo: campaignInfo
          }
          completedScenarioEventAnalytics({ payload: payload_, ownerId })
          resolve()
        }
      })
    }),
    'input-update': async (payload, preventDefault) => {
      preventDefault()
      if (payload.event && payload.event.node.input) {
        const { context: { store }, event: { node: { text, type, input: { name }, label } } } = payload
        const state = store.getState()
        const { mode } = payload.context.settings()
        const { input } = state
        const inputValue = input[name]
        const output = { key: name, value: inputValue }

        userInputAnalyticsEvent({ text, inputValue, name })
        userInputAnalytics({ name, inputValue })
        const { key, value, skip } = await parseUserInput({ type, label, output, input, uid, ownerId, mode })
        if (skip !== true && value !== undefined) {
          const saveRef = generateSaveRef(ownerId, uid)
          return saveRef.set(
            { [key]: userInputValueCheck(value) },
            {
              merge: true
            }
          )
        }
      }
    }
  }
}
